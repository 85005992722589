import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SmartImage } from "components/standart";
import { frontendApi, fileUrl } from "reducers/api";

const { api } = frontendApi.actions;
const getContacts = api.get("/contacts");

export const TelegramContacts = (props) => {
  const { color } = props;
  const [contacts, setContacts] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getContacts(
        {},
        {
          onSuccess(data) {
            setContacts(data);
          },
        }
      )
    );
    //
  }, []);

  return (
    <div className="text-center justify-content-center d-flex">
      {_.map(contacts, (contact, index) => {
        return (
          <a
            href={contact.url}
            target="_blank"
            className={`d-flex flex-column mx-4 text-center text-${color} text-decoration-none`}
            key={index}
          >
            <div>
              <SmartImage
                src={fileUrl(contact.fileId, "preview")}
                className="rounded-circle"
                style={{ width: "60px", height: "60px" }}
              />
            </div>
            <div className="mt-2 short-line-height">
              <small>{contact.title}</small>
            </div>
          </a>
        );
      })}
    </div>
  );
};
